
AOS.init({
    once: true,
});

$(window).on('load', function () {
    $('.loader').fadeOut();
});

$(document).ready(function(){
    $(".overlay-close").click(function(){
        $(".offcanvas").removeClass("show");
        $(".offcanvas-backdrop").removeClass("show");
        $(".offcanvas-backdrop").addClass("canvas-display");
        $("body").addClass("body-visible");
    });
});
  
$(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
        $('.navbar-main').addClass('top-header-fixed');
    } else {
        $('.navbar-main').removeClass('top-header-fixed');
    }
});



var swiper = new Swiper("#testimonial", {
    slidesPerView: 3,
    spaceBetween: 24,
    loop: true,
    swipeToSlide: true,
    navigation: {
      nextEl: ".swiper-button-next1",
      prevEl: ".swiper-button-prev1",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    breakpoints: {
        0: {
            slidesPerView: 1,
        },
        575: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 2,
        },
        992: {
            slidesPerView: 3,
        },
    },
});